import React from "react";
import PropTypes from "prop-types";
import ErrorComponent from "components/molecules/ErrorComponent";
import SectionContainer from "sections/SectionContainer";
import { FilterProvider } from "features/Filter/FilterProvider";

import { Text, Surface } from "@mm/ui";

import Sections from "./sections";
import ErrorBoundary from "../router/ErrorBoundary";

const PageSections = ({ sections, filterData, variant, voucherCode }) => {
  let propVariant = "";

  if (!sections) {
    return <></>;
  }

  if (variant !== "tariff" || variant !== "smartphone") {
    propVariant = "smartphone";
  } else {
    propVariant = variant;
  }

  return (
    <FilterProvider
      isListingPage
      variant={propVariant}
      data={filterData}
      shownFacets={{}}
    >
      {sections.map(
        (
          { elements, internalName, headlineText, useAsAnchor, error },
          index
        ) => {
          /**
           * internalName is used for the href in the JumpMarker Component
           */
          const sectionKey = elements[0]
            ? `${elements[0].type}-Container-${index}`
            : `${elements[0]}-Container-${index}`;

          if (elements[0].data.length <= 0) {
            if (error) {
              return (
                <ErrorComponent error={new Error(error)} key={sectionKey} />
              );
            }
          }

          const sectionComponents = elements.map(
            ({ type, ...props }, index) => {
              if (Object.prototype.hasOwnProperty.call(Sections, type)) {
                return React.createElement(Sections[type], {
                  ...props,
                  type,
                  sections,
                  key: type + index,
                  voucherCode,
                });
              }
              return null;
            }
          );

          return (
            <ErrorBoundary
              key={sectionKey}
              fallback={
                <Surface variant="narrow" shape="roundedSmall" mt={6}>
                  <Text variant="l">Es ist ein Fehler aufgetreten</Text>
                </Surface>
              }
            >
              <SectionContainer
                useAsAnchor={useAsAnchor}
                id={internalName}
                headlineText={headlineText}
                first={index === 0}
                variant={propVariant}
              >
                {sectionComponents}
              </SectionContainer>
            </ErrorBoundary>
          );
        }
      )}
    </FilterProvider>
  );
};

PageSections.defaultProps = {
  sections: [{}],
  voucherCode: "",
};

PageSections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  voucherCode: PropTypes.string,
};
export default PageSections;
