import { jsx as _jsx } from "react/jsx-runtime";
import { useReducer, useEffect, createContext, useContext, } from "react";
import { useInternetServiceProvider } from "../hooks";
import { useInternetOffers } from "@/features/Internet/hooks/useInternetOffers";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SYNC_DEFAULT_OFFERS"] = "SYNC_DEFAULT_OFFERS";
    ACTIONS["SYNC_CHECKED_DATA"] = "SYNC_CHECKED_DATA";
    ACTIONS["SET_AVAILABLE_PROVIDERS"] = "SET_AVAILABLE_PROVIDERS";
    ACTIONS["SET_PROVIDER_FILTER"] = "SET_PROVIDER_FILTER";
    ACTIONS["RESET_PROVIDER_FILTER"] = "RESET_PROVIDER_FILTER";
    ACTIONS["CLEAR_PROVIDER_FILTER"] = "CLEAR_PROVIDER_FILTER";
    ACTIONS["SET_SPEED_FILTER"] = "SET_SPEED_FILTER";
    ACTIONS["RESET_SPEED_FILTER"] = "RESET_SPEED_FILTER";
    ACTIONS["CLEAR_SPEED_FILTER"] = "CLEAR_SPEED_FILTER";
    ACTIONS["SET_PRICE_FILTER"] = "SET_PRICE_FILTER";
    ACTIONS["RESET_PRICE_FILTER"] = "RESET_PRICE_FILTER";
    ACTIONS["SET_CONNECTION_TYPE_FILTER"] = "SET_CONNECTION_TYPE_FILTER";
    ACTIONS["RESET_CONNECTION_TYPE_FILTER"] = "RESET_CONNECTION_TYPE_FILTER";
    ACTIONS["RESET_ALL_FILTER"] = "RESET_ALL_FILTER";
    ACTIONS["SET_IS_LOADING"] = "SET_IS_LOADING";
    ACTIONS["SET_IS_LOADING_DEFAULT_OFFERS"] = "SET_IS_LOADING_DEFAULT_OFFERS";
    ACTIONS["SET_SHOW_LOADING_OVERLAY"] = "SET_SHOW_LOADING_OVERLAY";
    ACTIONS["RESET_STATE"] = "RESET_STATE";
})(ACTIONS || (ACTIONS = {}));
const buildInitialPriceObject = (offers) => {
    if (offers.length === 0)
        return {
            active: false,
            min: 10,
            max: 99,
            minVal: 10,
            maxVal: 99,
        };
    const prices = offers
        .map((item) => item.tariff?.monthlyPrice)
        .filter(Number);
    const priceMin = Math.min(...prices);
    const priceMax = Math.max(...prices);
    return {
        active: false,
        min: priceMin,
        max: priceMax,
        minVal: priceMin,
        maxVal: priceMax,
    };
};
const filterOffers = (state) => {
    return state.allOffers
        .filter((offer) => {
        if (state.activeFilter.connectionType.length === 0) {
            return true;
        }
        return (offer.tariff?.connectionType &&
            state.activeFilter.connectionType.includes(offer.tariff.connectionType));
    })
        .filter((offer) => {
        if (state.activeFilter.connectionType.length > 0) {
            return offer;
        }
        return offer.default;
    })
        .filter((offer) => {
        if (state.activeFilter.provider.length === 0) {
            return true;
        }
        return (offer.tariff?.serviceProvider &&
            state.activeFilter.provider.includes(offer.tariff.serviceProvider));
    })
        .filter((offer) => {
        if (state.activeFilter.downloadSpeed.length === 0) {
            return true;
        }
        return (Number.isInteger(offer.tariff?.downloadSpeed) &&
            state.activeFilter.downloadSpeed.map((speed) => {
                if (speed == 0) {
                    return speed == Number(offer.tariff?.downloadSpeed);
                }
                return speed <= Number(offer.tariff?.downloadSpeed);
            })[0]);
    })
        .filter((offer) => {
        const { minVal, maxVal } = state.activeFilter.price;
        if (!state.activeFilter.price.active) {
            return true;
        }
        return (offer.tariff?.monthlyPrice &&
            offer.tariff.monthlyPrice >= minVal &&
            offer.tariff.monthlyPrice <= maxVal);
    });
};
const getMetaDataFromOffers = (offers) => {
    const getAllPossibleDownloadSpeeds = offers.map((item) => item.tariff?.downloadSpeed && Number(item.tariff.downloadSpeed));
    const orderedDownloadSpeeds = [
        // eslint-disable-next-line no-undef
        ...new Set(getAllPossibleDownloadSpeeds),
    ].filter(Number).sort((a, b) => {
        return a - b;
    });
    const getAllPossibleConnectionTypes = offers
        .map((item) => item.tariff?.connectionType)
        .filter(Boolean);
    const orderedConnectionTypes = [
        // eslint-disable-next-line no-undef
        ...new Set(getAllPossibleConnectionTypes),
    ].sort();
    return {
        connectionTypes: orderedConnectionTypes,
        downloadSpeeds: orderedDownloadSpeeds,
    };
};
const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SYNC_DEFAULT_OFFERS: {
            const { offers, promotionOffers } = action.payload;
            const { connectionTypes, downloadSpeeds } = offers
                ? getMetaDataFromOffers(offers)
                : {
                    connectionTypes: [],
                    downloadSpeeds: [],
                };
            return {
                ...state,
                offers: offers ?? [],
                allOffers: offers ?? [],
                connectionTypes,
                downloadSpeeds,
                ...(offers && {
                    activeFilter: {
                        ...state.activeFilter,
                        price: buildInitialPriceObject(offers),
                    },
                }),
                promotionOffers: promotionOffers ?? [],
                isLoadingOffers: false,
            };
        }
        case ACTIONS.SYNC_CHECKED_DATA: {
            const { address, offers, availabilityChecked, alternativeOffers, promotionOffers, } = action.payload;
            if (!offers) {
                return state;
            }
            const { connectionTypes, downloadSpeeds } = getMetaDataFromOffers(offers);
            return {
                ...state,
                address,
                availabilityChecked,
                alternativeOffers,
                promotionOffers,
                offers: filterOffers({
                    ...state,
                    allOffers: offers,
                    offers,
                }),
                allOffers: offers,
                connectionTypes,
                downloadSpeeds,
                activeFilter: {
                    ...state.activeFilter,
                    price: buildInitialPriceObject(offers),
                },
                isLoading: false,
                showLoadingOverlay: false,
            };
        }
        case ACTIONS.SET_AVAILABLE_PROVIDERS: {
            return {
                ...state,
                availableProviders: action.payload,
            };
        }
        case ACTIONS.SET_PROVIDER_FILTER: {
            const provider = action.payload;
            const selectedProviders = state.activeFilter.provider;
            if (selectedProviders.includes(provider)) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    provider: [...selectedProviders, provider],
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        provider: [...selectedProviders, provider],
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_PROVIDER_FILTER: {
            const provider = action.payload;
            const selectedProviders = state.activeFilter.provider;
            if (!provider) {
                return state;
            }
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    provider: selectedProviders.includes(provider)
                        ? selectedProviders.filter((selectedProvider) => selectedProvider !== provider)
                        : selectedProviders,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        provider: selectedProviders.includes(provider)
                            ? selectedProviders.filter((selectedProvider) => selectedProvider !== provider)
                            : selectedProviders,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_PROVIDER_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    provider: [],
                },
            };
        }
        case ACTIONS.SET_SPEED_FILTER: {
            const speed = action.payload;
            const selectedSpeeds = state.activeFilter.downloadSpeed;
            selectedSpeeds.push(speed);
            const cleanedSpeed = Array.from(new Set(selectedSpeeds));
            selectedSpeeds.push(speed);
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    downloadSpeed: cleanedSpeed,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        downloadSpeed: cleanedSpeed,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_SPEED_FILTER: {
            const speed = action.payload || 0;
            const selectedSpeed = state.activeFilter.downloadSpeed;
            if (!speed) {
                return state;
            }
            const filteredSpeed = selectedSpeed.includes(speed)
                ? selectedSpeed.filter((selectedSpeed) => selectedSpeed !== speed)
                : selectedSpeed;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    downloadSpeed: filteredSpeed,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        downloadSpeed: filteredSpeed,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_SPEED_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    downloadSpeed: [],
                },
            };
        }
        case ACTIONS.SET_PRICE_FILTER: {
            const price = action.payload;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    price,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        price,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_PRICE_FILTER: {
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    price: buildInitialPriceObject(state.allOffers),
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        price: buildInitialPriceObject(state.allOffers),
                    },
                }),
            };
        }
        case ACTIONS.SET_CONNECTION_TYPE_FILTER: {
            const connectionType = action.payload;
            const selectedConnectionTypes = state.activeFilter.connectionType;
            if (selectedConnectionTypes.includes(connectionType)) {
                return state;
            }
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    connectionType: [...selectedConnectionTypes, connectionType],
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        connectionType: [...selectedConnectionTypes, connectionType],
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_CONNECTION_TYPE_FILTER: {
            const connectionType = action.payload;
            const selectedConnectionTypes = state.activeFilter.connectionType;
            if (!connectionType) {
                return state;
            }
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    connectionType: selectedConnectionTypes.includes(connectionType)
                        ? selectedConnectionTypes.filter((type) => type !== connectionType)
                        : selectedConnectionTypes,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        connectionType: [...selectedConnectionTypes, connectionType],
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_ALL_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    connectionType: [],
                    downloadSpeed: [],
                    provider: [],
                    price: buildInitialPriceObject(state.allOffers),
                    status: false,
                },
            };
        }
        case ACTIONS.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload ?? true,
            };
        }
        case ACTIONS.SET_IS_LOADING_DEFAULT_OFFERS: {
            return {
                ...state,
                isLoading: action.payload ?? true,
            };
        }
        case ACTIONS.SET_SHOW_LOADING_OVERLAY: {
            return {
                ...state,
                showLoadingOverlay: action.payload ?? true,
            };
        }
        case ACTIONS.RESET_STATE: {
            return {
                ...initialState,
                allOffers: state.allOffers,
                availableProviders: state.availableProviders,
                offers: state.offers,
                page: state.page,
                promotionOffers: state.promotionOffers,
            };
        }
        default: {
            throw new Error(`Reducer action not set: ${action.type}`);
        }
    }
};
const initialState = {
    address: {
        zip: "",
        city: "",
        street: "",
        streetNumber: "",
    },
    activeOffer: [],
    activeFilter: {
        connectionType: [],
        downloadSpeed: [],
        provider: [],
        price: {
            active: false,
            min: 10,
            max: 99,
            minVal: 10,
            maxVal: 99,
        },
        status: false,
    },
    allOffers: [],
    alternativeOffers: [],
    availableProviders: [],
    availabilityChecked: false,
    connectionTypes: [],
    downloadSpeeds: [],
    offers: [],
    promotionOffers: [],
    isLoading: false,
    isLoadingOffers: false,
    // eslint-disable-next-line no-undef
    tenant: "MEDIAMARKT",
    page: undefined,
    showLoadingOverlay: false,
    showRegards: false,
    showOffers: false,
};
const context = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => { },
});
export const InternetProvider = ({ children, page }) => {
    const { data: serviceProviders } = useInternetServiceProvider();
    const { data: { address, availabilityChecked, offers, promotionOffers } = {
        availabilityChecked: false,
        offers: [],
        promotionOffers: [],
    }, isLoading: isLoadingOffers, } = useInternetOffers({
        enabled: Boolean(page?.internet?.showOffers) ||
            Boolean(page?.internet?.showRegards),
        onSuccess: (data) => {
            dispatch({
                type: ACTIONS.SYNC_CHECKED_DATA,
                payload: {
                    ...initialState,
                    ...data,
                },
            });
        },
    }, page.url);
    const { internet: { serviceProvider: pageInternetProvider, showRegards, showOffers, } = {
        serviceProvider: "",
        showRegards: false,
        showOffers: false,
    }, } = page;
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        address,
        availabilityChecked,
        isLoadingOffers,
        page,
        showRegards,
        showOffers,
    });
    useEffect(() => {
        if (promotionOffers.length > 0 ||
            (offers.length > 0 && serviceProviders && serviceProviders.length > 0)) {
            dispatch({
                type: ACTIONS.SYNC_DEFAULT_OFFERS,
                payload: {
                    offers: pageInternetProvider
                        ? offers.filter((offer) => offer.tariff?.serviceProviderId === pageInternetProvider)
                        : offers,
                    promotionOffers,
                },
            });
        }
    }, [offers, serviceProviders]);
    useEffect(() => {
        if (serviceProviders) {
            if (pageInternetProvider.length > 1) {
                dispatch({
                    type: ACTIONS.SET_AVAILABLE_PROVIDERS,
                    payload: serviceProviders.filter((provider) => provider.id === pageInternetProvider),
                });
            }
            else {
                dispatch({
                    type: ACTIONS.SET_AVAILABLE_PROVIDERS,
                    payload: serviceProviders,
                });
            }
        }
    }, [serviceProviders, pageInternetProvider]);
    return (_jsx(context.Provider, { value: { state, dispatch }, children: children }));
};
export const useInternetProvider = () => useContext(context);
