import React from "react";
import PropTypes from "prop-types";

import { Flex } from "@mm/ui";

import { useOfferConfigurator } from "features/Offer";

import "./styles.scss";

import {
  Eyecatchers,
  TariffBadge,
  TileAnchor,
  TileBox,
  OfferHeadline,
} from "components/atoms";

import {
  PriceDisplay,
  ProductAccessoryDisplay,
  TariffMonthlyPriceLabel,
  Loading,
} from "components/molecules";

import {
  eyecatcherPropTypes,
  hardwarePropTypes,
  tariffPropTypes,
} from "sharedPropTypes";

const PhoneQuarterWidth = ({
  eyeCatchers,
  hardware: { pdpLink, price, image, name, link },
  tariff,
  tariff: { id: tariffId, commission, pricingIntervals },
}) => {
  const { state } = useOfferConfigurator();

  const { accessories, extendedVariant } = state;

  if (!state || !extendedVariant) return <Loading variant="teaser" />;

  const { manufacturer } = extendedVariant;

  return (
    <TileBox variant="tile" className="msd-offer-teaser-four-columns-hardware">
      <Eyecatchers eyecatchers={eyeCatchers || []} variant="offerItem" />
      <TileAnchor
        href={link}
        dataLayerObj={{
          eventCategory: "Produktteaser",
          eventAction: "click",
          eventLabel: `Details:${pdpLink}:PhoneQuarterWidth`,
        }}
      >
        <OfferHeadline
          additionalData={accessories}
          text={name}
          prefix={manufacturer}
          lines={2}
        />
        <ProductAccessoryDisplay
          singleImage={image}
          name={name}
          accessories={accessories}
          manufacturer={manufacturer}
        />
      </TileAnchor>
      <Flex alignItems="center" justifyContent="space-between" mt={4}>
        <PriceDisplay
          tariffId={tariffId}
          posttext={
            <TariffMonthlyPriceLabel
              commission={commission}
              price={pricingIntervals.averagePrice}
            />
          }
          pretext="Einmaliger Gerätepreis"
          priceIntervals={pricingIntervals.intervals}
          price={price}
          size="s"
          direction="column"
          className="quarterWidth"
        />
        <TariffBadge tariff={tariff} framed />
      </Flex>
    </TileBox>
  );
};

PhoneQuarterWidth.propTypes = {
  index: PropTypes.number,
  eyeCatchers: eyecatcherPropTypes.isRequired,
  hardware: hardwarePropTypes.isRequired,
  tariff: tariffPropTypes.isRequired,
};

PhoneQuarterWidth.defaultProps = {
  index: 0,
};

export default PhoneQuarterWidth;
