import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Box, Image, Icon, Surface } from "@mm/ui";

import { getSelectedExtendedVariant } from "utils/variantUtils";

import { hardwarePropTypes } from "sharedPropTypes";

import { StyledOutSign } from "./soldoutsign.styled";

const ContentWrap = styled.div`
  position: relative;
  align-items: stretch;
  display: flex;
  height: 100%;
  max-height: 300px;
`;

const ProductImage = styled(Image)`
  height: ${(props) => (props.apple ? "113%" : "100%")};
`;

const AccessoryImage = styled(Image)`
  display: flex;
  height: ${(props) => (props.length === 2 ? "50%" : "inherit")};
  img {
    width: 100%;
    height: ${(props) => (props.length === 2 ? "100%" : "90%")};
    object-fit: contain;
    align-self: center;
  }
`;

const CenteredIcon = styled(Surface)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-23px, -23px);
  z-index: 2;
  width: auto;
  border-radius: 50%;
  padding: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevation3};
`;

const StyledIcon = styled(Icon)`
  width: 46px;
  height: 46px;
  color: black;
  :after {
    font-size: 24px;
  }
`;

const OfferColumnStyle = {
  position: "absolute",
  margin: "0px auto",
  left: "0",
  top: "calc(40%)",
  right: "0",
  translate: "0",
};

function ProductAccessoryDisplay({
  accessories,
  activeAccessoryVariantIds,
  className,
  manufacturer,
  isHeroHardware,
  isSoldOut,
  name,
  image,
  imageHeight,
  singleImage,
}) {
  const [accessoriesImages, setAccessoriesImages] = useState([]);

  useEffect(() => {
    if (activeAccessoryVariantIds) {
      const selectedEbootisId = Object.keys(activeAccessoryVariantIds).map(
        (key) => activeAccessoryVariantIds[key].ebootisId
      );

      const matchedVariants = selectedEbootisId.flatMap((ebootisId) => {
        const variants = accessories.flatMap((accessory) => {
          return accessory.extendedVariants.filter(
            (extendedVariant) => extendedVariant.variant.ebootisId === ebootisId
          );
        });

        return variants;
      });

      setAccessoriesImages(matchedVariants);
    }

    if (!activeAccessoryVariantIds) {
      const selectedVariants = accessories.flatMap((accessory) => {
        return accessory.extendedVariants.filter(
          (extendedVariant) =>
            extendedVariant.isSelected == true ||
            extendedVariant.variant.default == true
        );
      });

      setAccessoriesImages(selectedVariants);
    }
  }, [activeAccessoryVariantIds, accessories]);

  return (
    <Box className={className} width="100%" maxWidth="300px" mx="auto">
      {accessories.length === 0 ? (
        <Box height={imageHeight} style={{ position: "relative" }}>
          {isSoldOut && (
            <StyledOutSign style={OfferColumnStyle}>Ausverkauft</StyledOutSign>
          )}
          <ProductImage
            srcSet={image}
            src={singleImage}
            alt={`${manufacturer} ${name}`}
            apple={isHeroHardware && manufacturer === "apple"}
          />
        </Box>
      ) : (
        <ContentWrap>
          <CenteredIcon level="2">
            <StyledIcon variant="teaser" name="plus" />
          </CenteredIcon>
          <Box height={imageHeight} width="50%">
            {isSoldOut && (
              <StyledOutSign style={OfferColumnStyle}>
                Ausverkauft
              </StyledOutSign>
            )}
            <ProductImage
              srcSet={image}
              src={singleImage}
              alt={`${manufacturer} ${name}`}
              apple={isHeroHardware && manufacturer === "apple"}
            />
          </Box>
          <Box
            height={imageHeight}
            width="50%"
            display="flex"
            flexDirection="column"
          >
            {!accessories[0]?.pkBonus &&
              accessoriesImages.length > 0 &&
              accessoriesImages.map((accessory) => {
                return (
                  <AccessoryImage
                    key={accessory.hardwareName}
                    length={accessories.length}
                    srcSet={accessory.variant.images[0]}
                    alt={`${accessory.manufacturer} ${accessory.hardwareName}`}
                  />
                );
              })}

            {accessories[0]?.pkBonus &&
              accessories.map((accessory) => {
                const variantsImages = getSelectedExtendedVariant(
                  accessory.extendedVariants
                ).variant.images[0];
                return (
                  <AccessoryImage
                    key={accessory.name}
                    length={accessories.length}
                    srcSet={variantsImages}
                    alt={`${accessory.manufacturer} ${accessory.name}`}
                  />
                );
              })}
          </Box>
        </ContentWrap>
      )}
    </Box>
  );
}

ProductAccessoryDisplay.propTypes = {
  accessories: PropTypes.arrayOf(hardwarePropTypes),
  className: PropTypes.string,
  isHeroHardware: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  name: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  singleImage: PropTypes.string,
  manufacturer: PropTypes.string.isRequired,
  imageHeight: PropTypes.string,
};

ProductAccessoryDisplay.defaultProps = {
  className: undefined,
  accessories: [],
  isSoldOut: false,
  imageHeight: "160px",
  isHeroHardware: false,
};

export default ProductAccessoryDisplay;
