import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import styled from "styled-components";
import { useOfferConfigurator, SET_ACTIVE_TARIFF, SYNC, } from "@/features/Offer";
import { Image, Box } from "@mm/ui";
import { Bulletlist, GeneralModalShell, Price } from "@/components/atoms";
import Header from "./Header/index";
import Footer from "./Footer/index";
import { getCarrierLabel, getCarrierOverwrite } from "./util";
import { getTariffBadge } from "@/utils/getTariffBadge";
const SvgWrapper = styled.span `
  position: relative;
  z-index: 1;

  svg {
    display: block;
    path {
      fill: ${({ theme }) => theme.colors.grey[4]};
    }
  }
`;
const Teaser = styled("section") `
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 4px;

  &:active,
  &:focus,
  &:hover {
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    transition-property: border-color, box-shadow, transform;
    box-shadow: rgb(0 0 0 / 7%) 0px 8px 10px 1px,
      rgb(0 0 0 / 6%) 0px 3px 14px 2px, rgb(0 0 0 / 10%) 0px 5px 5px -3px;
  }
`;
const BorderWrapper = styled("div") `
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #cdcaca;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const ContentWrapper = styled("div") `
  padding: 20px;

  > div {
    img {
      width: 100%;
    }
  }

  .badgeWrapper {
    .tariffBadge {
      margin: 54px auto 50px;

      .tariffBadgeImg {
        display: block;
        margin: 0 auto;
        width: 130px;
      }

      &--small {
        width: 100%;
        text-align: right;
        margin: 0 0 20px 0;

        .tariffBadgeImg {
          display: inline-block;
          width: 50px;
        }
      }
    }

    .couponBadge {
      width: 130px;
      margin: 0 auto;
    }
  }
`;
const CouponWrapper = styled("div") `
  position: relative;
  margin: 20px 0;
  mark {
    display: inline-block;
  }
`;
const CouponPrice = styled(Price) `
  position: absolute;
  top: 44%;
  left: 50%;
  font-size: 25px;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const DetailEyeCatcher = styled.div `
  margin-top: 1rem;
  display: flex;
  gap: 0.25rem;
`;
const Coupon = ({ price }) => (_jsxs(CouponWrapper, { children: [_jsx(Image, { src: "/img/mm-neu/geschenk-coupon@3x.png", alt: "Geschenk Coupon" }), _jsx(CouponPrice, { forceCentDisplay: true, price: price, variant: "secondary" })] }));
const OfferTeaserSimOnly = ({ accessories, availabilityChecked, coupon, eyeCatchers, detailEyeCatcher, tariff, offerGroupUrl, cartButtonQaName, newCheckout, offer, }) => {
    const { state, dispatch } = useOfferConfigurator();
    useEffect(() => {
        if (tariff) {
            dispatch({
                type: SYNC,
                payload: { rootInfo: tariff },
            });
            dispatch({
                type: SET_ACTIVE_TARIFF,
                payload: { calculateExtendTariff: { tariff } },
            });
        }
    }, [tariff]);
    if (!tariff) {
        return null;
    }
    const { serviceProvider, bullets } = tariff;
    const badge = getTariffBadge(tariff);
    return (_jsxs(Teaser, { children: [_jsx(Header, { accessories: accessories, eyeCatchers: eyeCatchers, headline: tariff.dataVolume, subHeadline: tariff.name, provider: serviceProvider, carrier: getCarrierLabel(tariff.carrier), carrierOverwrite: getCarrierOverwrite(tariff.carrier) }), _jsxs(BorderWrapper, { className: "borderWrapper", children: [_jsxs(ContentWrapper, { children: [_jsxs("div", { className: "badgeWrapper", children: [_jsx("div", { className: coupon?.value && coupon?.value > 0
                                            ? "tariffBadge tariffBadge--small"
                                            : "tariffBadge", children: tariff.carrier && (_jsx("img", { "aria-hidden": "true", src: `https://content.ekontor24.net/mediamarkt/img/mm-neu/${badge}.svg`, alt: `${badge} logo`, className: coupon?.value && coupon?.value > 0
                                                ? "tariffBadgeImg tariffBadgeImg--small"
                                                : "tariffBadgeImg" })) }), coupon?.value && coupon.value > 0 && (_jsx("div", { className: "couponBadge", children: _jsx(Coupon, { price: coupon.value }) }))] }), coupon?.value && coupon.value > 0 && (_jsx(Box, { mb: "10px", children: _jsx("span", { children: _jsxs("b", { children: ["mit ", coupon.value, "\u20AC Geschenk-Coupon"] }) }) })), _jsxs("div", { children: [_jsx(Bulletlist, { list: bullets, variant: "checkmark", listLength: 3 }), detailEyeCatcher?.eyeCatcher && (_jsxs(DetailEyeCatcher, { children: [detailEyeCatcher?.eyeCatcher && (_jsx("span", { children: _jsx("b", { children: detailEyeCatcher.eyeCatcher }) })), _jsx(GeneralModalShell, { opener: _jsx(SvgWrapper, { "aria-label": "Mehr Informationen", role: "dialog", "aria-modal": "true", children: _jsx("i", { className: "icon-MM_iconfont_Info" }) }), children: _jsx("div", { children: detailEyeCatcher?.eyeCatcherInfo }) })] }))] })] }), state.rootInfo && (_jsx(Footer, { accessories: accessories, availabilityChecked: availabilityChecked, coupon: coupon, tariff: tariff, offerGroupUrl: offerGroupUrl, cartButtonQaName: cartButtonQaName, newCheckout: newCheckout, offer: offer }))] })] }));
};
export default OfferTeaserSimOnly;
