import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import ThankYouPage from "@/pages/ThankYouPage";
import InternetPage from "@/pages/InternetPage";
import WaipuPage from "@/pages/WaipuPage";
import ListingPageSimonly from "@/pages/ListingPageSimonly";
import ProductConfigurationPage from "@/pages/ProductConfigurationPage";
import { CheckoutPage } from "@/pages/CheckoutPage";
import { CheckoutError, ErrorPage } from "@/pages/CheckoutPage/errors";
import { CheckoutType } from "@/types";
import SEPAPage from "@/pages/SEPAPage";
import { AgbPage, Agb1und1Page, AgbFreenetPage, AgbKlarMobilPage, AgbSuperSelectPage, AgbKlarTelefonicaPage, AgbO2MyHomePage, AgbVodafonePage, ImprintPage, PrivacyPolicyPage, RightOfWithdrawalPage, } from "@/pages/LegalPages";
import { checkoutBasePaths } from "./checkoutBasePaths";
import ErrorBoundary from "./ErrorBoundary";
export const routes = [
    {
        element: _jsx(InternetPage, {}),
        path: checkoutBasePaths.internet,
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(InternetPage, {}),
        path: `${checkoutBasePaths.internet}/:providerName`,
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(ProductConfigurationPage, { type: CheckoutType.internet }),
        path: `${checkoutBasePaths.internet}/pks/:offerGroupUrl`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(CheckoutPage, { type: CheckoutType.internet }),
        path: `${checkoutBasePaths.internet}/checkout`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(CheckoutPage, { type: CheckoutType.internet }),
        path: `${checkoutBasePaths.internet}/checkout/step/:step`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(ThankYouPage, {}),
        path: `${checkoutBasePaths.internet}/checkout/danke`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(Navigate, { replace: true, to: "/waipu/checkout/step/1" }),
        path: `${checkoutBasePaths.streaming}/checkout`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(CheckoutPage, { type: CheckoutType.streaming }),
        path: `${checkoutBasePaths.streaming}/checkout/step/:step`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(ThankYouPage, {}),
        path: `${checkoutBasePaths.streaming}/checkout/danke`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(Navigate, { replace: true, to: "/tarife/checkout/step/1" }),
        path: `${checkoutBasePaths.simOnly}/checkout`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(CheckoutPage, { type: CheckoutType.simOnly }),
        path: `${checkoutBasePaths.simOnly}/checkout/step/:step`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(ThankYouPage, {}),
        path: `${checkoutBasePaths.simOnly}/checkout/danke`,
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(WaipuPage, {}),
        path: "/waipu",
        errorElement: _jsx(CheckoutError, {}),
    },
    {
        element: _jsx(SEPAPage, {}),
        path: "/sepa",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbPage, {}),
        path: "/agb",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(Agb1und1Page, {}),
        path: "/agb-1und1",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbFreenetPage, {}),
        path: "/agb-freenet",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbKlarMobilPage, {}),
        path: "/agb-klarmobil",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbKlarTelefonicaPage, {}),
        path: "/agb-telefonica",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbSuperSelectPage, {}),
        path: "/agb-superselect",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbO2MyHomePage, {}),
        path: "/agb-o2myhome",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(AgbVodafonePage, {}),
        path: "/agb-vodafone",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(RightOfWithdrawalPage, {}),
        path: "/widerrufsrecht",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(ImprintPage, {}),
        path: "/impressum",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        element: _jsx(PrivacyPolicyPage, {}),
        path: "/datenschutz",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
    {
        // Fallback if route is matched in web.php, but not here
        element: _jsx(CheckoutError, {}),
        path: "*",
    },
    {
        element: (_jsx(ListingPageSimonly, { queryParams: {
                type: "tariff",
            } })),
        path: "/tarife",
        errorElement: _jsx(ErrorPage, { error: new Error("TBD") }),
    },
];
export const Router = () => {
    const router = createBrowserRouter(routes);
    return (_jsx(_Fragment, { children: _jsx(ErrorBoundary, { children: _jsx(RouterProvider, { router: router }) }) }));
};
export default Router;
