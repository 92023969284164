export const getTariffBadge = (tariff) => {
    if (!tariff.serviceProvider || !tariff.carrier)
        return undefined;
    const { carrier, serviceProvider } = tariff;
    switch (serviceProvider) {
        case "o2": {
            switch (carrier) {
                case "Super Select": {
                    return "superselect";
                }
                case "super-select": {
                    return "superselect";
                }
                default: {
                    return "o2";
                }
            }
        }
        case "letsGoMobile": {
            return "letsGoMobile";
        }
        case "klarmobil": {
            switch (carrier?.toLowerCase()) {
                case "vodafone klarmobil": {
                    return "km-vodafone";
                }
                case "telekom klarmobil": {
                    return "km-telekom";
                }
                default: {
                    return "klarmobil";
                }
            }
        }
        case "freenet": {
            switch (carrier?.toLowerCase()) {
                case "vodafone": {
                    return "md-vodafone";
                }
                case "telefonica": {
                    return "md-telefonica";
                }
                default: {
                    return "md-telekom";
                }
            }
        }
        default: {
            return undefined;
        }
    }
};
