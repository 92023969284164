import React from "react";

import { useOfferConfigurator } from "features/Offer";

import { OfferHeadline, TariffBadge } from "components/atoms";

import {
  ProductAccessoryDisplay,
  Loading,
  LegalNotes,
} from "components/molecules";

import {
  eyecatcherPropTypes,
  hardwarePropTypes,
  tariffPropTypes,
} from "sharedPropTypes";

import "./styles.scss";

function objectToArray(obj) {
  let resultArray = [];
  let hasOwnProperty = Object.prototype.hasOwnProperty;

  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) {
      if (obj[key] !== "") {
        resultArray.push(obj[key]);
      }
    }
  }

  return resultArray;
}

const PhoneHalfWidth = ({
  doubleCard,
  eyeCatchers,
  onlineOnly,
  hardware: { link, name, image, price },
  tariff,
  tariff: { id: tariffId, monthlyPrice, legalNotes },
}) => {
  const { state } = useOfferConfigurator();

  const { accessories, extendedVariant } = state;

  if (!state || !extendedVariant) return <Loading variant="teaser" />;

  const { manufacturer } = extendedVariant;

  const modifiedEyeCatcher = objectToArray(eyeCatchers) || false;

  return (
    <a
      className="msd-offer-teaser-two-columns msd-teaser-box msd-teaser-box--flex-column"
      href={link}
    >
      <div className="msd-offer-teaser-two-columns-content">
        <ul className="msd-badge-wrapper">
          {modifiedEyeCatcher &&
            modifiedEyeCatcher.map((eyeCatcher) => (
              <li className="msd-badge" key={eyeCatcher}>
                <span className="msd-badge-text">{eyeCatcher}</span>
              </li>
            ))}
        </ul>
        <div className="msd-offer-teaser-two-columns-grid">
          <div className="msd-offer-teaser-two-columns-product-display">
            <ProductAccessoryDisplay
              singleImage={image}
              name={name}
              imageHeight="250px"
              manufacturer={manufacturer}
              accessories={accessories}
            />
          </div>
          <div className="msd-offer-teaser-two-columns-infos">
            <span className="msd-offer-teaser-two-columns-manufacturer">
              {manufacturer}
            </span>
            <OfferHeadline
              additionalData={accessories}
              text={name}
              prefix={manufacturer}
              lines={2}
              variant="offerTeaser"
            />
            <div className="msd-offer-teaser-two-columns-tariff-info-wrapper">
              <TariffBadge tariff={tariff} />
              {doubleCard && <span className="double">x2</span>}
              <div className="tariff-info">
                {tariff?.dataVolume > 0 && (
                  <span className="tariff-info-volume">
                    {tariff?.dataVolume}
                  </span>
                )}
                <div className="tariff-info-attributes">
                  <span className="item">
                    {tariff?.dataVolume > 0 ? "GB" : "unbegrenzt"}
                  </span>
                  {tariff && (
                    <span className="item">
                      {tariff.lte && "LTE"} {tariff.fiveG && "5G"}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <ul className="msd-badge-wrapper msd-badge-wrapper--zero-margin msd-badge-wrapper--auto-margin-top">
              {onlineOnly && (
                <li className="msd-badge msd-badge--onlineOnly">
                  <span className="msd-badge-text">Nur online</span>
                </li>
              )}
            </ul>
            <div className="msd-offer-teaser-two-columns-one-time-price">
              <span className="price">
                {price},- €
                <LegalNotes
                  tariffLegalNotes={legalNotes}
                  tariffId={tariffId}
                  stageStyle={false}
                  pl="1"
                />
              </span>
              <span className="text">Einmaliger Gerätepreis</span>
            </div>
            <div className="msd-offer-teaser-two-columns-monthly-price">
              <span className="text">
                Monatlicher Grundpreis {monthlyPrice} €
                <LegalNotes
                  tariffLegalNotes={legalNotes}
                  tariffId={tariffId}
                  stageStyle={false}
                  pl="1"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

PhoneHalfWidth.propTypes = {
  eyeCatchers: eyecatcherPropTypes.isRequired,
  hardware: hardwarePropTypes.isRequired,
  tariff: tariffPropTypes.isRequired,
};

export default PhoneHalfWidth;
