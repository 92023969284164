import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Headline, Surface, SliderWithInput } from "@mm/ui";
import ClearSvg from "@core/svgs/reset-filter.svg";
import dataLayer from "@/helper/dataLayer";
import { useViewport } from "@core/theme";
import { useInternetProvider } from "@/features/Internet";
import { getServiceProviderLabel, } from "@/types";
import { ACTIONS } from "@/features/Internet/context";
const StyledClearSvg = styled(ClearSvg) `
  position: relative;
  top: 2px;
  transform: scale(0.7);
`;
const TabsNavigation = styled("div") `
  display: grid;
  grid-template-columns: 1fr;

  .tabsNavigationItem {
    color: #000000;
    font-size: 16px;
    text-align: center;
    padding: 10px 25px;
    border: 0;
    border-bottom: 2px solid #c2c2c2;
    background: white;
    cursor: pointer;

    &.active {
      font-weight: 600;
      border-bottom: 2px solid #df0000;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.md.min}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
const TabsContent = styled("div") `
  display: none;
  margin: 24px 0 0;

  &.active {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`;
const FilterPill = styled("button") `
  color: #000000;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  background: white;
  border: 1px solid #979797;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &.active {
    border: 1px solid #df0000;
  }
  &.fixedWidth {
    min-width: 8.5rem;
  }
`;
const ActiveFilterSection = styled("div") `
  display: flex;
  gap: 24px;
  margin: 24px 0 0;

  .filterResetButton {
    color: #000000;
    display: block;
    font-size: 16px;
    padding: 12px 24px;
    background: #f4f4f4;
    border: 1px solid #cdcaca;
    border-radius: 4px;
    cursor: pointer;

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover,
    &.active {
      border: 1px solid #df0000;
    }
  }

  .filterDeleteButton {
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;

    &__icon-red {
      > .icon-MM_iconfont_ArrowR {
        color: red;
      }
    }
  }
`;
const PriceFilterWrapper = styled("div") `
  max-width: 90%;
  padding: 0 12px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    max-width: 40%;
    margin: 0;
  }
`;
const InternetFilterSection = () => {
    const { state: { allOffers = [], availableProviders, connectionTypes, downloadSpeeds, activeFilter: { connectionType, downloadSpeed, provider, price, status }, }, dispatch, } = useInternetProvider();
    const { greaterThan: { md }, } = useViewport();
    const [activeTab, setActiveTab] = useState(md ? 1 : 2);
    const [priceRange, setPriceRange] = useState({
        active: false,
        min: 10,
        max: 99,
        minVal: 10,
        maxVal: 99,
    });
    useEffect(() => {
        if (price) {
            setPriceRange(price);
        }
    }, [price]);
    const priceMinValue = Math.round(priceRange.minVal);
    const priceMaxValue = Math.round(priceRange.maxVal);
    if (allOffers.length < 1) {
        return null;
    }
    const formattedTechnology = (technology) => {
        switch (technology) {
            case "cable":
                return "Kabel";
            case "dsl":
                return "DSL";
            default:
                return technology;
        }
    };
    return (_jsxs(Surface, { variant: "narrow", shape: "roundedSmall", mt: 6, children: [_jsx(Headline, { variant: "h4", children: "Weitere Tarife an deiner Adresse" }), _jsxs(TabsNavigation, { children: [_jsx("button", { type: "button", className: activeTab === 1
                            ? "tabsNavigationItem active"
                            : "tabsNavigationItem inActive", onClick: () => {
                            setActiveTab(1);
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ActiveTab",
                                eventLabel: "Internet:Action:Filter:ActiveProvider",
                            });
                        }, "data-qa": "filterProvider", children: "Anbieter" }), _jsx("button", { type: "button", className: activeTab === 2
                            ? "tabsNavigationItem active"
                            : "tabsNavigationItem inActive", onClick: () => {
                            setActiveTab(2);
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ActiveTab",
                                eventLabel: "Internet:Action:Filter:ActiveTabDownloadSpeed",
                            });
                        }, "data-qa": "filterSpeed", children: "Downloadgeschwindigkeit" }), _jsx("button", { type: "button", className: activeTab === 3
                            ? "tabsNavigationItem active"
                            : "tabsNavigationItem inActive", onClick: () => {
                            setActiveTab(3);
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ActiveTab",
                                eventLabel: "Internet:Action:Filter:ActiveTabMonthlyPrice",
                            });
                        }, "data-qa": "filterMonthly", children: "Monatlicher Preis" }), _jsx("button", { type: "button", className: activeTab === 4
                            ? "tabsNavigationItem active"
                            : "tabsNavigationItem inActive", onClick: () => {
                            setActiveTab(4);
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ActiveTab",
                                eventLabel: "Internet:Action:Filter:ActiveTabTechnology",
                            });
                        }, "data-qa": "filterTech", children: "Technologie" })] }), _jsxs(TabsContent, { className: activeTab === 1 ? "active" : "inActive", children: [_jsx(FilterPill, { className: provider.length === 0 ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                            type: ACTIONS.CLEAR_PROVIDER_FILTER,
                        }), children: "Alle" }), availableProviders.map(({ providerName }) => (_jsx(FilterPill, { className: provider.includes(providerName) ? "active" : "inActive", type: "button", "data-qa": `filter-${providerName}`, onClick: () => {
                            dispatch({
                                type: ACTIONS.SET_PROVIDER_FILTER,
                                payload: providerName,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "SetProvider",
                                eventLabel: "Internet:Action:Filter:SetProvider",
                            });
                        }, children: getServiceProviderLabel(providerName) }, `provider-${providerName}`)))] }), _jsxs(TabsContent, { className: activeTab === 2 ? "active" : "inActive", children: [_jsx(FilterPill, { className: downloadSpeed.length === 0 ? "active" : "inActive", "data-qa": "filter-default", type: "button", onClick: () => dispatch({
                            type: ACTIONS.CLEAR_SPEED_FILTER,
                        }), children: "Alle" }), downloadSpeeds.map((item) => (_jsxs(FilterPill, { className: `${downloadSpeed.includes(item) ? "active" : "inActive"} fixedWidth`, type: "button", "data-qa": `speed-${item}`, onClick: () => {
                            dispatch({
                                type: ACTIONS.CLEAR_SPEED_FILTER,
                            });
                            dispatch({
                                type: ACTIONS.SET_SPEED_FILTER,
                                payload: item,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "SetSpeed",
                                eventLabel: "Internet:Action:Filter:SetSpeed",
                            });
                        }, children: ["ab ", item, " MBit/s"] }, `speed-${item}`)))] }), _jsx(TabsContent, { className: activeTab === 3 ? "active" : "inActive", children: _jsx(PriceFilterWrapper, { children: _jsx(SliderWithInput, { step: 2, value: priceRange, suffix: "\u20AC", onChange: (value) => {
                            dispatch({
                                type: ACTIONS.SET_PRICE_FILTER,
                                payload: {
                                    ...value,
                                    active: true,
                                },
                            });
                            setPriceRange(value);
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "SetPrice",
                                eventLabel: "Internet:Action:Filter:SetPrice",
                            });
                        } }) }) }), _jsx(TabsContent, { className: activeTab === 4 ? "active" : "inActive", children: connectionTypes.map((item) => (_jsx(FilterPill, { className: connectionType.includes(item) ? "active" : "inActive", type: "button", "data-qa": `type-${item}`, onClick: () => {
                        dispatch({
                            type: ACTIONS.SET_CONNECTION_TYPE_FILTER,
                            payload: item,
                        });
                        dataLayer({
                            eventCategory: "Internet:Filter",
                            eventAction: "SetConnectionType",
                            eventLabel: "Internet:Action:Filter:SetConnectionType",
                        });
                    }, children: formattedTechnology(item) }, `type-${item}`))) }), status && (_jsxs(ActiveFilterSection, { children: [provider.map((item) => (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                            dispatch({
                                type: ACTIONS.RESET_PROVIDER_FILTER,
                                payload: item,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ResetProvider",
                                eventLabel: "Internet:Action:Filter:ResetProvider",
                            });
                        }, children: [getServiceProviderLabel(item), " ", _jsx(StyledClearSvg, {})] }, `provider-${item}`))), downloadSpeed.map((item) => (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                            dispatch({
                                type: ACTIONS.RESET_SPEED_FILTER,
                                payload: item,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ResetSpeed",
                                eventLabel: "Internet:Action:Filter:ResetSpeed",
                            });
                        }, children: [item, " MBit/s ", _jsx(StyledClearSvg, {})] }, `speed-${item}`))), price.active && (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                            dispatch({
                                type: ACTIONS.RESET_PRICE_FILTER,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ResetPrice",
                                eventLabel: "Internet:Action:Filter:ResetPrice",
                            });
                        }, children: [priceMinValue, "\u20AC - ", priceMaxValue, "\u20AC ", _jsx(StyledClearSvg, {})] })), connectionType.map((item) => (_jsxs("button", { type: "button", className: "filterResetButton", onClick: () => {
                            dispatch({
                                type: ACTIONS.RESET_CONNECTION_TYPE_FILTER,
                                payload: item,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ResetConnectionType",
                                eventLabel: "Internet:Action:Filter:ResetConnectionType",
                            });
                        }, children: [formattedTechnology(item), " ", _jsx(StyledClearSvg, {})] }, `connectionType-${item}`))), status && (_jsxs("button", { type: "button", className: "filterDeleteButton filterDeleteButton__icon-red", onClick: () => {
                            dispatch({
                                type: ACTIONS.RESET_ALL_FILTER,
                            });
                            dataLayer({
                                eventCategory: "Internet:Filter",
                                eventAction: "ResetAllFilter",
                                eventLabel: "Internet:Action:Filter:ResetAllFilter",
                            });
                        }, children: [_jsx("i", { className: "icon-MM_iconfont_ArrowR" }), " Auswahl zur\u00FCcksetzen"] }))] }))] }));
};
export default InternetFilterSection;
