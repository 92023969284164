import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const ContentAdTeaser = ({ content }) => {
  const {
    headlineText,
    images,
    linkUrl,
    linkText,
    onlineOnly,
    preHeadline,
    eyeCatchers,
    contentText,
  } = content;

  if (!content) {
    return <p>Bitte überprüfen Sie ihre eingeben Werte</p>;
  }

  return (
    <a
      href={linkUrl}
      className="msd-content-ad-teaser msd-teaser-box msd-teaser-box--flex-column"
      aria-label={headlineText || contentText}
    >
      {images && (
        <div
          className="msd-teaser-image"
          style={{ backgroundImage: `url(${window.CDN_URL + images?.xlUrl})` }}
        />
      )}
      <div className="msd-teaser-content">
        <ul className="msd-badge-wrapper">
          {onlineOnly && (
            <li className="msd-badge msd-badge--onlineOnly">
              <span className="msd-badge-text">Nur online</span>
            </li>
          )}
          {eyeCatchers &&
            eyeCatchers.map((eyeCatcher) => (
              <li className="msd-badge" key={eyeCatcher}>
                <span className="msd-badge-text">{eyeCatcher}</span>
              </li>
            ))}
        </ul>
        <h3 className="msd-teaser-preHeadline">{preHeadline}</h3>
        <p className="msd-teaser-headline">{headlineText}</p>
        <p className="msd-teaser-text">{contentText}</p>
        <span className="msd-teaser-link">{linkText || "Mehr erfahren"}</span>
      </div>
    </a>
  );
};

ContentAdTeaser.propTypes = {
  content: PropTypes.shape({
    headlineText: PropTypes.string,
    images: PropTypes.shape({
      lgUrl: PropTypes.string,
      mdUrl: PropTypes.string,
      smUrl: PropTypes.string,
      xlUrl: PropTypes.string,
      xsUrl: PropTypes.string,
    }),
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    onlineOnly: PropTypes.bool,
    preHeadline: PropTypes.string,
    eyeCatchers: PropTypes.arrayOf(PropTypes.string),
    contentText: PropTypes.string,
  }),
};

ContentAdTeaser.defaultProps = {
  content: {
    headlineText: "Lorem ipsum dolor sit amet",
    images: {
      lgUrl:
        "/img/redesign/third_width_banner/superselect2/thirdWidth_imageOnlyAd_540x400_xs.png",
      mdUrl:
        "/img/redesign/third_width_banner/superselect2/thirdWidth_imageOnlyAd_540x400_xs.png",
      smUrl:
        "/img/redesign/third_width_banner/superselect2/thirdWidth_imageOnlyAd_540x400_xs.png",
      xlUrl:
        "/img/redesign/third_width_banner/superselect2/thirdWidth_imageOnlyAd_540x400_xs.png",
      xsUrl:
        "/img/redesign/third_width_banner/superselect2/thirdWidth_imageOnlyAd_540x400_xs.png",
    },
    linkUrl: "/superselect/",
    linkText: "Mehr erfahren",
    onlineOnly: true,
    preHeadline: "Nur bis 29.06.",
    eyeCatchers: ["Foobar 1", "Foobar 2", "Foobar 3"],
    contentText:
      "Entdecke jetzt unsere attraktiven Angebote aus der MediaMarkt Super Select Tarifwelt",
  },
};

export default ContentAdTeaser;
