import { extendURLParams } from "@/utils/fetchData";
import { mapState } from "@/utils/mapState";
export const mutateCheckout = async (data) => {
    const { tenant, environment, restUrl } = extendURLParams();
    /**
     * Prepares state as valid payload
     *
     * @param state
     */
    const transformState = (state) => {
        return mapState(state, (value) => {
            // trim all whitespace at the start and end of string values
            if (typeof value === "string" || value instanceof String)
                return value.trim();
            if (value === "")
                return undefined;
            return value;
        });
    };
    return fetch(`${restUrl}/v1/checkout?tenant=${tenant}&environment=${environment}`, {
        method: "put",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(transformState(data)),
    })
        .then((response) => {
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.json(); // This returns a promise
    })
        .catch((error) => {
        // Handle errors
        // eslint-disable-next-line no-console
        console.error("There was a problem with the checkout post fetch:", error);
    });
};
