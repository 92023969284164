import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { addDays, parse, startOfDay } from "date-fns";
import { CheckoutSection, CheckoutSectionHeadline, CheckoutSectionText, DatePicker, RadioList, } from "../../components";
import { ACTIONS, useCheckoutState } from "../../context/CheckoutContext";
import { useCheckout } from "../../hooks/api";
import { useField, useValidation, ValidateOnEvent, } from "../../hooks";
import styled from "styled-components";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
`;
const InputWrapper = styled.div `
  flex: 1;
`;
export const ActivationDateSection = ({ defaultValues, schema, options, }) => {
    const { state, dispatch } = useCheckoutState();
    const { selectableStartInDays, selectableEndInDays, selectionTooltip } = options ?? {
        selectableStartInDays: 0,
        selectableEndInDays: 365,
        selectionTooltip: undefined,
    };
    const startDate = startOfDay(addDays(new Date(), Number(selectableStartInDays)));
    const endDate = startOfDay(addDays(new Date(), Number(selectableEndInDays)));
    const { errors: activationDateSelectionErrors, fieldState: activationDateSelection, setFieldState: setActivationDateSelection, } = useField("activationDateSelection", schema, Boolean(state?.activationDateSelection || defaultValues?.activationDateSelection) ?? false);
    const { errors: activationDateErrors, fieldState: activationDate, setFieldState: setActivationDate, } = useField("activationDate", schema, String(state?.activationDate || defaultValues?.activationDate) ?? "", {
        validateOn: ValidateOnEvent.Change,
        forceContextState: true,
    });
    const { isFetching, isLoading } = useCheckout();
    const { clearErrors, } = useValidation();
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: "Zu wann w\u00FCnschst du die Aktivierung deines Anschlusses?" }), selectionTooltip && (_jsx(CheckoutSectionText, { children: selectionTooltip })), _jsx(RadioList, { id: "activationDateSelection", checked: activationDateSelection
                    ? "activationDate--manual"
                    : "activationDate--asap", items: [
                    {
                        key: "activationDate--asap",
                        label: "Schnellstmöglich",
                    },
                    {
                        key: "activationDate--manual",
                        label: "Zu einem bestimmten Zeitpunkt",
                    },
                ], onChange: (key) => {
                    if (!isLoading && !isFetching) {
                        clearErrors("activationDate");
                        if (key === "activationDate--asap") {
                            dispatch({
                                type: ACTIONS.RESET_FIELDS,
                                payload: "activationDate",
                            });
                        }
                        setActivationDateSelection(key === "activationDate--manual");
                    }
                }, error: activationDateSelectionErrors?.[0]?.message, disabled: isLoading || isFetching }), activationDateSelection && (_jsx(_Fragment, { children: _jsx(Row, { children: _jsx(InputWrapper, { children: _jsx(DatePicker, { id: "activationDate", label: "Aktivierungsdatum*", selectableStart: startDate, selectableEnd: endDate, selected: activationDate
                                ? parse(activationDate, "dd.MM.yyyy", new Date())
                                : undefined, openToDate: startDate, onChange: (date) => setActivationDate(date?.toLocaleString("de-DE", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            }) ?? ""), layout: activationDateErrors && "error", message: activationDateErrors?.[0]?.message, disabled: isLoading || isFetching }) }) }) }))] }));
};
