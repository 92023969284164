import { jsx as _jsx } from "react/jsx-runtime";
import React, { Component } from "react";
import { ErrorPage } from "../pages/CheckoutPage/errors";
class ErrorBoundary extends Component {
    state = {
        hasError: false,
    };
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error("Uncaught error:", error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            let element;
            if (typeof this.props.fallback === "function") {
                element = React.createElement(this.props.fallback);
            }
            else {
                element = this.props.fallback;
            }
            if (React.isValidElement(element)) {
                return element;
            }
            return _jsx(ErrorPage, { error: { message: this.state.error?.message } });
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
