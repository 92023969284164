import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Surface, Text } from "@mm/ui";
import { useViewport } from "@core/theme";

import CheckmarkSvg from "@core/svgs/checkmark.svg";
import SkonSvg from "@core/svgs/skon.svg";

import Pagelogo from "../../atoms/Pagelogo";
import MobileNaviButton from "../../atoms/MobileNaviButton";
import OrderHotline from "../../atoms/OrderHotline";
import PageOverlay from "../../atoms/PageOverlay";
import Link from "../../atoms/Link";
import dataLayer from "helper/dataLayer";

import Cart from "../Cart";
import MobileNavigation from "../MobileNavigation";
import DesktopNavigation from "../DesktopNavigation";

const Outer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 16px 0;
  color: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    padding: 32px 0 0 0;
  }
`;

const Inner = styled("div")`
  display: flex;
  justify-content: space-between;
  min-height: ${(props) => props.reduced && "100px"};

  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    padding: 0 20px 0 23px;
  }
  @media (max-width: ${(props) => props.theme.breakpointsMap.sm.max}px) {
    padding: 0 4px 0 0;
  }
`;

const Navi = styled("div")`
  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    flex-basis: 64%;
  }
`;

const SubNavi = styled("div")`
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: stretch;
    flex-grow: 1;
    flex-basis: 0;
    padding-bottom: 20px;
  }
`;

const NaviButton = styled(MobileNaviButton)`
  margin: 0;
  font-size: 20px;
  padding: 0.875rem 1.5rem 0.875rem 0.75rem;
`;

const StyledOrderHotline = styled(OrderHotline)`
  z-index: 1;
  align-self: baseline;
  margin-right: ${(props) => (props.reduced ? "-200px" : "9px")};

  @media (min-width: ${(props) => props.theme.breakpointsMap.xl.min}px) {
    margin-right: ${(props) => (props.reduced ? "-200px" : "24px")};
  }
`;

const OrderstatuslinkText = styled(Link)`
  padding-left: 0;
  height: fit-content;
  font-weight: 600;
  line-height: 1.4;
  color: ${(props) => props.theme.colors.white};

  svg {
    height: 12px;
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }

  :after {
    display: none;
  }
  :before {
    background: ${(props) => props.theme.colors.white};
    left: 0;
    width: 100%;
  }
  &:hover {
    color: ${(props) => props.theme.colors.white};
  }
`;

const LogoContainer = styled("div")`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.breakpointsMap.md.max}px) {
    align-items: center;
  }
  @media (max-width: ${(props) => props.theme.breakpointsMap.sm.max}px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  }
`;

const SkonContainer = styled("span")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 8px;

  @media (max-width: ${(props) => props.theme.breakpointsMap.sm.max}px) {
    margin-right: 0;
    margin-bottom: 2px;
    font-size: 10px;
    > :last-child {
      display: none;
    }
  }
`;

const SkonLogo = styled(SkonSvg)`
  height: 27px;
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius[1]};

  @media (min-width: ${(props) => props.theme.breakpointsMap.md.min}px) {
    height: 40px;
    padding: 5px;
  }
  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    height: 56px;
    padding: 8px;
  }
`;

const StyledCart = styled(Cart)`
  margin-left: 16px;

  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    align-self: flex-end;
    margin-left: 24px;
  }
`;

const WebshopLabel = styled(Text)`
  min-width: 7.1em;
`;

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const Brandheader = ({ navigation = [], reducedHeader, handleAnchorHover }) => {
  const viewport = useViewport();
  const reduced = reducedHeader || !(navigation ?? []).length;

  const [mobileNaviOpen, setIsMobileNaviOpen] = useState(false);

  const toggleMobileNavi = (isOpen) => {
    setIsMobileNaviOpen(!isOpen);
    if (!isOpen) {
      document.body.addEventListener("touchmove", preventDefault);
      dataLayer({
        eventCategory: "Top Navigation",
        eventAction: "click",
        eventLabel: "Open:Menu",
      });
    } else {
      document.body.removeEventListener("touchmove", preventDefault);
      dataLayer({
        eventCategory: "Top Navigation",
        eventAction: "click",
        eventLabel: "Close:Menu",
      });
    }
  };

  const fixBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const releaseBodyScroll = () => {
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (mobileNaviOpen) {
      fixBodyScroll();
    } else {
      releaseBodyScroll();
    }
  }, [mobileNaviOpen]);

  return (
    <Outer>
      {viewport.lessThan.lg && (
        <>
          {mobileNaviOpen && (
            <PageOverlay onClick={() => toggleMobileNavi(mobileNaviOpen)} />
          )}
          <MobileNavigation
            navigation={navigation}
            closeNavi={() => toggleMobileNavi(mobileNaviOpen)}
            isOpen={mobileNaviOpen}
          />
        </>
      )}
      <Surface variant="wide">
        <Inner reduced={reduced}>
          <Navi>
            {viewport.lessThan.lg ? (
              <>
                <NaviButton
                  ariaLabel="Mobile Navigation öffnen."
                  onClick={() => toggleMobileNavi(mobileNaviOpen)}
                />
                <Pagelogo href="/" color="white" />
              </>
            ) : (
              <>
                <Pagelogo href="/" color="white" />
                {!reduced && (
                  <DesktopNavigation
                    navigation={navigation}
                    handleAnchorHover={handleAnchorHover}
                  />
                )}
              </>
            )}
          </Navi>
          <SubNavi>
            {viewport.greaterThan.lg && (
              <StyledOrderHotline showDetails={false} reduced={reduced} />
            )}
            {!reduced && viewport.greaterThan.lg && (
              <OrderstatuslinkText
                href="/faq/"
                onClick={() =>
                  dataLayer({
                    eventCategory: "Top Navigation",
                    eventAction: "click",
                    eventLabel: "FAQ",
                  })
                }
                title="FAQ"
              >
                <CheckmarkSvg /> Hilfe & Service
              </OrderstatuslinkText>
            )}
            <LogoContainer>
              <SkonContainer>
                <WebshopLabel
                  variant={viewport.lessThan.md ? "xs" : "smallCopy"}
                >
                  Ein Webshop der
                </WebshopLabel>
                <Text variant={viewport.lessThan.md ? "xs" : "smallCopy"}>
                  S-KON Gruppe
                </Text>
              </SkonContainer>
              <SkonLogo />
            </LogoContainer>
            {!reduced && <StyledCart viewport={viewport} />}
          </SubNavi>
        </Inner>
      </Surface>
    </Outer>
  );
};

Brandheader.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.any).isRequired,
  reducedHeader: PropTypes.bool,
};

Brandheader.defaultProps = {
  reducedHeader: false,
};

export default Brandheader;
