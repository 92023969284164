import React, { Fragment } from "react";
import PropTypes from "prop-types";

const StandardPageTemplate = ({
  breadcrumb,
  header,
  footer,
  sections,
  className,
}) => (
  <>
    {header}
    {breadcrumb}
    <main id="page-main-content" className={className}>
      {sections.map((Component, index) => {
        const elemKey = `fragment-${index}`; // @TODO: ESLint: Set correct key
        return <Fragment key={elemKey}>{Component}</Fragment>;
      })}
    </main>
    {footer}
  </>
);

StandardPageTemplate.propTypes = {
  breadcrumb: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  sections: PropTypes.arrayOf(PropTypes.node).isRequired,
};

StandardPageTemplate.defaultProps = {
  breadcrumb: <></>,
  className: "",
};

export default StandardPageTemplate;
